//FlexDocument

//THIS EDITOR IS FOR THE PLACEMENT OF DOCUMENTS WITHIN SOME OF OUR PAGES.
//There may be a general usage CMS in play at a later date for IT Troubleshooting, Shop Training, Etc.

//THIS WILL HAVE A CUSTOM API ENDPOINT 
//FlexDocument.php

//The primary goal of this document getter/setter is to be able to place a document on any one given
//record in the database. This may be items, orders, order items, customers, products, etc.
//		BOILERPLATE - Example Code
//		PRODUCTS - Sales notes, Tech notes - How do we separate these?
//		ITEMS - Document damage to units
//		ORDERS - Document outbound or return issues, customer requests.
//		CUSTOMERS - Customization of orders by customer request defaults, marketing, etc.

//PERMISSIONS: Handled by response from API. If the user isn't allowed, we just won't render this functional component.

//DATA STORAGE:
//We likely need 1 API endpoint unless we want to pass the data.
//We may also want to hide certain things for permissions reasons. Instead of making complicated
//SQL statements, we can maybe try this to hide parts of a response:
// $res = Model::where('your query')->get();
// $res->makeHidden(['column_one','column_two','column_n']);
// return response()->json($res);

//OPTIONS (passed in props):
//These options will affect the way we fetch and store 
//		documentids [array of ids] (can be sorted by function?)
//		maxdocuments - option to limit documents to n (remove add button)
//		header - option to add an introductory header - blank will not render
//		addbtn - BOOL, option to add document
//		addbtntxt - "Add return notes..",   "Add Item Notes"
//
//		hasToggle - Allow a menu toggle so the page isn't clogged with a massive document - this may cause us to need REDUX


//Save button will need to be WITHIN resultant because some users won't be able to save.



//Can we pass a SaveChanges() function to this document storage?
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from "axios";

//EditorJS
import EditorJS from '@editorjs/editorjs';
import Header from "editorjs-header-with-alignment";
//import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Table from '@editorjs/table';
import Checklist from '@editorjs/checklist';
import DragDrop from 'editorjs-drag-drop';
//import ColorPlugin from 'editorjs-text-color-plugin/dist/bundle.js';
import ColorPlugin from 'editorjs-text-color-plugin'; //Does not currently work.
//const ColorPlugin = require('editorjs-text-color-plugin'); 
import Alert from 'editorjs-alert';
import Paragraph from 'editorjs-paragraph-with-alignment';
import ToggleBlock from 'editorjs-toggle-block';
import CodeTool from '@editorjs/code';
import CodeBox from '@bomdi/codebox';
//import editorjsCodeflask from '@calumk/editorjs-codeflask';
import editorjsCodecup from '@calumk/editorjs-codecup';
import RawTool from '@editorjs/raw';


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';


import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import PostAddIcon from '@mui/icons-material/PostAdd';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PrintIcon from '@mui/icons-material/Print';

//Datetime formatting
import Moment from 'react-moment';

//Buttons
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext";

//Printing
import { useReactToPrint } from 'react-to-print';

//import '../../editor-js-code.js';

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;
//Test this. We need to serve up localhost:8000 in development, not localhost:3000
var appendpoint = process.env.APP_URL;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};



export function FlexDocument(props) {

	let history = useHistory();

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	const componentRef = useRef();
	console.log(props);
	//To Do: Setup Tools:
	//Tools can be completely left out of config! They also need to be truthy from the client.
	//Config with declarative true\false is easier for boilerplate useage and toggling settings.
	var tools = {};
	var dragdrop = false;
	//Default have paragraph plugin
	tools.paragraph = {
		class: Paragraph,
		inlineToolbar: true
	}
	if (props.hasOwnProperty('tools')) {
		if (props.tools.hasOwnProperty('header')) {
			if (props.tools.header) {
				tools.header = {
					class: Header,
					config: {
						placeholder: 'Enter a header',
						levels: [1, 2, 3],
						defaultLevel: 3,
						defaultAlignment: 'center'
					}
				};
			}
		}

		if (props.tools.hasOwnProperty('list')) {
			if (props.tools.list) {
				tools.list = {
					class: List,
					inlineToolbar: true
				};
			}
		}
		if (props.tools.hasOwnProperty('image')) {
			if (props.tools.image) {
				tools.image = {
					class: ImageTool,
					inlineToolbar: true,
					config: {
						endpoints: {
							byFile: dbendpoint + "/upload-file", // Your backend file uploader endpoint
							byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
						},
						types: "image/*",
					}
				};
			}
		}
		if (props.tools.hasOwnProperty('table')) {
			if (props.tools.table) {
				tools.table = {
					class: Table,
					inlineToolbar: true
				};
			}
		}
		if (props.tools.hasOwnProperty('checklist')) {
			if (props.tools.checklist) {
				tools.checklist = {
					class: Checklist,
					inlineToolbar: true
				};
			}
		}


		if (props.tools.hasOwnProperty('dragdrop')) {
			if (props.tools.dragdrop) {
				dragdrop = true;
			}
		}
		if (props.tools.hasOwnProperty('alert')) {
			if (props.tools.alert) {
				tools.alert = {
					class: Alert
				};
			}
		}
		if (props.tools.hasOwnProperty('toggle')) {
			if (props.tools.toggle) {
				tools.toggle = {
					class: ToggleBlock,
					inlineToolbar: true
				};
			}
		}
		if (props.tools.hasOwnProperty('code')) {
			if (props.tools.code) {
				tools.code = {
					class: editorjsCodecup
				};
			}
		}

		if (props.tools.hasOwnProperty('raw')) {
			if (props.tools.raw) {
				tools.raw = {
					class: RawTool
				};
			}
		}

		if (props.tools.hasOwnProperty('color')) {
			if (props.tools.color) {
				tools.Color = {
					class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
					config: {
						colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
						defaultColor: '#FF1300',
						type: 'text',
						customPicker: true // add a button to allow selecting any colour  
					},
				};
			}
		}

	}



	const [docState, setDocState] = useState({
		docinit: false,
		documentid: props.documentid,
		dbkey: props.dbkey,
		firstchangedetected: false,
		allownew: props.allownew,
		pendingdocument: false,
		tools: tools,
		document: {},
		readOnly: props.readOnly
	});
	let docstate = Object.assign({}, docState);

	function UpdateDocState(stateobject) {
		setDocState(stateobject);
	}

	const [editorInstance, setEditorInstance] = useState(null);



	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();
	const btnPendingSave = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});


	useEffect(() => {
		if (!docstate.docinit) {
			//Diagnostic:
			//console.log("Localstate ID: " + localstate.itemdata.ID);
			//LoadItem();
			//console.log("Setting data:");

			console.log("GETTING DATA:");
			console.log("DocumentID: " + docstate.documentid);
			if (docstate.documentid) {
				const postdata = {
					ID: docstate.documentid
				};

				axios.post(dbendpoint + '/document', postdata, defaultpostoptions).then(res => {
					//API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						//All new API calls should return a status.
						if (res.data.Status === "Success") {
							console.log(res);
							docstate.document = res.data.item;
							docstate.docinit = true;
							UpdateDocState(docstate);
						}
						if (res.data.Status === "Failure") {
							//Failure error
							//errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
						}
					} else {
						//Non-200 message from server.
						//errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
					}
				}).catch(err => {
					//Error getting any response
					//errors.NewError({ errmsg: "Caught request error.", errshow: true, errtimeout: 5, errtype: "danger" })
				});

			} else {
				//Determine if we're allowed to make a new document:
				if (props.allownew) {
					docstate.allownew = true;
					UpdateDocState(docstate);
				}

			}



			//To Do: Wrap this up into our fetch!

			// dispatch(setDocument({
			// 	value: 88,
			// 	documentid: 4,
			// 	docloaded: true,
			// 	data: {
			// 		"time": 1552744582955,
			// 		"blocks": [
			// 			{
			// 				"type": "image",
			// 				"data": {
			// 					"file": {
			// 						"url": "https://deluxepcs.app/legacy/icon/browser/abilon.png"
			// 					}
			// 				}
			// 			},
			// 			{
			// 				"id": "oUq2g_tl8y",
			// 				"type": "header",
			// 				"data": {
			// 					"text": "This is a header block.",
			// 					"level": 2
			// 				}
			// 			},
			// 		],
			// 		"version": "2.11.10"
			// 	}
			// }));

			// //Setting document to initialized too quickly will still result in the the DIV ID not being found on the page because it is not 
			// //yet rendered. How short can our delay need to be? Starting at 500ms.
			// setTimeout(()=>{
			// 	docstate.docinit = true;
			// 	UpdateDocState(docstate);
			// }, 500);

		}

		//Assume data now loaded:
		if (docstate.docinit) {
			console.log("DOC LOADED.");
			console.log(document);
			console.log(docstate.document);
			// document = useSelector(selectDocument);
			// console.log(document);
			const editor = new EditorJS({

				/** 
				 * Id of Element that should contain the Editor 
				 */
				holder: 'editorjs' + docstate.documentid,
				data: docstate.document.data,
				/** 
				 * Available Tools list. 
				 * Pass Tool's class or Settings object for each Tool you want to use 
				 */
				tools: docstate.tools,
				readOnly: docstate.readOnly,
				onChange: (api, event) => {
					console.log(api);
					//Bug causes this to fire on load. We'll caput
					console.log('Now I know that Editor\'s content changed!', event);
					console.log(docstate.firstchangedetected);
					if (!docstate.firstchangedetected) {
						console.log('set started');
						docstate.firstchangedetected = true;
						//For some reason, readOnly wants to get wrecked onChange.
						docstate.readOnly = false;
						UpdateDocState(docstate);
					} else {
						btnSave.current.style.display = "none";
						btnPendingSave.current.style.display = "";
					}
				},
				onReady: () => {
					if (dragdrop) {
						//alert("dragdrop!");
						new DragDrop(editor);
					}
				}

			});
			setEditorInstance(editor);

			//TEST CUSTOM BUTTONS HERE. NEED TO INSTANTIATE EDITOR BEFORE WE CAN USE IT'S METHODS!
			if (docstate.readOnly && props.userPerms.update) {
				console.log("Here is where the error is thrown.");
				//At this point the toggleReadOnly button should be rendered.

				// document.getElementById('toggleReadOnly'+docstate.documentid).addEventListener('click', () => {
				// 	editor.readOnly.toggle().then(() => {
				// 		console.log('ReadOnly mode: ', editor.readOnly.isEnabled);
				// 	});
				// });

				const toggleButton = document.getElementById('toggleReadOnly' + docstate.documentid);
				if (toggleButton) {
					toggleButton.addEventListener('click', () => {
						editor.readOnly.toggle().then(() => {
							console.log('ReadOnly mode: ', editor.readOnly.isEnabled);
						});
					});
				} else {
					console.warn('Toggle button not found for document ID: ' + docstate.documentid);
				}
			}

		}
	}, [docState.docinit]);

	const SaveChanges = () => {
		if (editorInstance) {
			editorInstance.save().then((outputData) => {
				console.log('Article data: ', outputData);
				docstate.document.data = outputData;
				const postdata = {
					item: docstate.document,
					dbkey: props.dbkey
				};
				//Apply title:
				console.log("SAVE: " + props.title);
				if (props.title) {
					postdata.item.Title = props.title;
				}
				axios.post(dbendpoint + props.documentendpoint + "/updatedocument", postdata, defaultpostoptions).then(res => {
					//API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						//All new API calls should return a status.
						if (res.data.Status === "Success") {
							console.log(res);
							btnSave.current.style.display = "";
							btnPendingSave.current.style.display = "none";
						}
						if (res.data.Status === "Failure") {
							//Failure error
							//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
						}
					} else {
						//Non-200 message from server.
						//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
					}
				});
			}).catch((error) => {
				console.log('Saving failed: ', error)
			});
		}
	};

	const ToggleReadOnly = () => {
		docstate.readOnly = false;
		UpdateDocState(docstate);
	}

	const AddDocument = () => {
		//TO DO: Make request to API and get a new documentid.


		const postdata = {
			ID: props.itemid,
			dbkey: props.dbkey,
			documenttype: props.documenttype
			//document:docstate.document,
		};
		//We set a document endpoint so we can customize per-table how we want to handle documents of a certain nature. Some areas may call up multiple documents!
		axios.post(dbendpoint + props.documentendpoint + '/adddocument', postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					console.log(res);
					//To Do: Set documentid!
					docstate.documentid = res.data.ID;
					docstate.document.ID = res.data.ID;
					docstate.readOnly = false;

					setTimeout(() => {
						docstate.docinit = true;
						docstate.pendingdocument = true;
						UpdateDocState(docstate);
					}, 1000);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	const onChangeValue = (event, itemkey) => {
		var oldvalue = docstate.document[itemkey];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {

			// if (itemkey === "SomeBoolean") {
			// 	if (event.target.checked) {
			// 		localstate.itemdata.SomeBoolean = 1;
			// 	} else {
			// 		localstate.itemdata.SomeBoolean = 0;
			// 	}
			// }

			// //Float
			// if (itemkey === "Cost" || itemkey === "Price" || itemkey === "Margin") {
			// 	if (!oldvalue){
			// 		oldvalue="0";
			// 	}
			// 	//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
			// 	newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
			// 	if (newvalue) {
			// 		itemRefs.current[itemkey+"El"].value = newvalue;
			// 		localstate.itemdata[itemkey] = newvalue;
			// 		//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
			// 		const inputElement = itemRefs.current[itemkey+"El"];
			// 		inputElement.type = 'text';
			// 		inputElement.focus();
			// 		inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
			// 		inputElement.type = 'number';
			// 	} else {
			// 		console.log("No newvalue returned, set value in localstate, but leave input untouched.");
			// 		console.log(parseFloat(event.target.value).toFixed(2));
			// 		localstate.itemdata[itemkey] = parseFloat(event.target.value).toFixed(2);
			// 	}
			// }

			// //Positive Integer:
			// if (itemkey === "PositiveInteger") {
			// 	if (!oldvalue){
			// 		oldvalue="0";
			// 	}
			// 	//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
			// 	newvalue = RestrictInputNumber(newvalue, oldvalue, event, "positiveinteger"); //All arguments required. New universal function for all pages. However handle result below.
			// 	if (newvalue) {
			// 		itemRefs.current[itemkey+"El"].value = newvalue;
			// 		localstate.itemdata[itemkey] = newvalue;
			// 		//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
			// 		const inputElement = itemRefs.current[itemkey+"El"];
			// 		inputElement.type = 'text';
			// 		inputElement.focus();
			// 		inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
			// 		inputElement.type = 'number';
			// 	} else {
			// 		console.log("No newvalue returned, set value in localstate, but leave input untouched.");
			// 		console.log(parseInt(event.target.value));
			// 		localstate.itemdata[itemkey] = parseInt(event.target.value);
			// 	}
			// }

			//All others
			if (itemkey === "Title" || itemkey === "LastName" || itemkey === "Date") {
				docstate.document[itemkey] = newvalue;
			}

			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			UpdateDocState(docstate);
		}

	}

	const onTogglePrivate = (event) => {
		console.log(event.target);
		if (docstate.document.Private===0){
			//Switch to not checked
			docstate.document.Private = 1;
		} else {
			docstate.document.Private = 0;
		}
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		UpdateDocState(docstate);
	}

	const [deleteMsg, setDeleteMsg] = useState(false);
	const markDelete = () => {
		setDeleteMsg(()=>{
			return <>
				Document is marked for deletion! Click 'Save Changes' to confirm deletion.
			</>
		});

		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		docstate.document.SoftDelete = 1;
		UpdateDocState(docstate);
		
	}

		/* View Options Menu */
		const [showViewOptionsMenu, setViewOptionsMenu] = useState(null);
		const ShowViewOptionsMenu = (event) => {
			setViewOptionsMenu(event.currentTarget); 
		}
		const CloseViewOptionsMenu = () => {
			setViewOptionsMenu(null);
		}

	return (
		<>
			{(props.header) &&
				<div style={{ textAlign: "center", margin: "10px" }}>
					<h3>{props.header}</h3>
				</div>
			}
			{(props.introduction) &&
				<div style={{ textAlign: "center", margin: "10px", fontSize: "14px" }}>
					{props.introduction}
				</div>
			}

		


			<Box sx={{ '& button': { m: 1 }, displayPrint: 'none' }}>

				{(docstate.document.DocumentType === "user") &&
					<Button
						className={classes.bluebtn}
						color="primary" variant="contained"
						onClick={() => history.push('/documents')}>
						<KeyboardBackspaceIcon></KeyboardBackspaceIcon>&nbsp;Back to Documents
					</Button>
				}




				{(docstate.allownew && !docstate.documentid && !docstate.pendingdocument && props.userPerms.create === 1 && !docstate.docinit) &&

					<Button
						className={(props.userPerms.create === 1) ? classes.bluebtn : classes.hidden}
						color="primary" variant="contained"
						onClick={() => AddDocument()}
						ref={el => btnSave.current = el}>
						<PostAddIcon></PostAddIcon>&nbsp;{props.addbtntext}
					</Button>
				}

				{/*I'm thinking any potential toggle button needs to be rendered before docinit */}

				{(docstate.readOnly) &&
					<>
						{(props.userPerms.update === 1 && docstate.documentid) &&
							<Button
								id={"toggleReadOnly" + docstate.documentid}
								className={(props.userPerms.update === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								sx={{ displayPrint: 'none', margin: "0px" }}
								onClick={() => ToggleReadOnly()}>
								{props.editbtntext}
							</Button>
						}
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => handlePrint()}>
							Print
						</Button>
						<br></br>
					</>
				}


				{(docstate.docinit && !docstate.readOnly && props.userPerms.update === 1) &&
					<>
						<Button
							className={(props.userPerms.update === 1) ? classes.bluebtn : classes.hidden}
							color="primary" variant="contained"
							onClick={() => SaveChanges()}
							sx={{ displayPrint: 'none', margin: "0px" }}
							ref={el => btnSave.current = el}>
							<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;{props.savebtntext}
						</Button>
						<Button
							className={(props.userPerms.update === 1 || props.userPerms.create === 1) ? classes.bluebtn : classes.hidden}
							color="primary" variant="contained"
							style={{ display: "none" }}
							onClick={() => SaveChanges()}
							sx={{ displayPrint: 'none', margin: "0px" }}
							ref={el => btnPendingSave.current = el}>
							<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;{props.savebtntext}
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => handlePrint()}>
							<PrintIcon></PrintIcon>&nbsp;Print
						</Button>
					</>
				}

				{(docstate.document.DocumentType === "user" && !docstate.readOnly) &&
				<>
				<Button
					className={classes.bluebtn}
					color="primary" variant="contained"
					aria-haspopup="true"
					onClick={ShowViewOptionsMenu}>
					Document Options
				</Button>

					<Menu
						className={classes.bluebtn}
						sx={{ zIndex: 3000 }} // Adjust the z-index here
						color="primary"
						id="view-options-menu"
						anchorEl={showViewOptionsMenu}
						keepMounted
						open={Boolean(showViewOptionsMenu)}
						onClose={CloseViewOptionsMenu}
					>
						<Box sx={{margin:"10px"}}>
							Make Public: <Switch checked={docstate.document.Private===0}
								onChange={(event) => onTogglePrivate(event)}
							/><br></br>
							
							<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => markDelete()}>
							<DeleteIcon></DeleteIcon>&nbsp;Delete Document
						</Button>
						
						{/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
						</Box>
					
					</Menu>
				</>
					
				}
			</Box>



			{(docstate.docinit) &&
				<>
					<div ref={componentRef} style={{ padding: "10px 20px 0px 20px", textAlign: "left" }}>
					{(deleteMsg) &&
						<div style={{ textAlign: "center", margin: "10px", fontSize: "16px", fontWeight:"bold", color:"orange" }}>
							{deleteMsg}
						</div>
					}
						<Box style={{ height: "1px", borderBottom: "1px solid #EEE" }} sx={{ displayPrint: 'none', margin: "0px" }}></Box>

						{/* Only user or department documents have editable Titles. */}
						{(docstate.document.DocumentType === "user" || docstate.document.DocumentType === "department") &&
							<Box>
								<input className={classes.flexiteminput}
									maxLength="100" //Needs max length
									type="text"
									value={docstate.document.Title}
									disabled={(docstate.document.CreatedByUserID !== appContext.userId)}
									placeholder={"Title"}
									style={{ textAlign: "center", fontSize: "30px" }}
									onChange={(event) => onChangeValue(event, "Title")}
								/>
							</Box>
						}


						<div id={"editorjs" + docstate.documentid}></div> {/* EDITORJS CONTAINER */}
					</div>
					{(props.showupdateinfo) &&
						<div style={{ textAlign: "right", fontSize: "12px", color: "#CCC" }}>
							<div style={{display:"inline-block", verticalAlign:"top"}}>
							{(docstate.document.updateuser) &&
								<>
									Last update: {docstate.document.updateuser.firstname + " " + docstate.document.updateuser.lastname} on <Moment element='span' format="MMMM D, YYYY [at] h:mma">{docstate.document.updated_at}</Moment><br></br>
								</>
							}

							{props.title}
						</div>
							
						</div>
					}
				</>
			}


		</>

	);
}