//Product View


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";

//Contexts
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//Datetime formatting
import Moment from 'react-moment';
//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";


import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ViewListIcon from '@mui/icons-material/ViewList';

//Buttons
import Button from '@mui/material/Button';

//Datetime Pickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


/* ##########################  Configuration  ########################## */

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;
var hostbase = process.env.REACT_APP_BASE;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Axios Long Post
const longpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 20000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1, 1, 2, 3, 3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function (acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	}, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};



const ProductDetails = (props) => {
	let history = useHistory();
	//URL Param for ID
	let { id } = useParams();
	console.log(id);

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();
	const btnPendingSave = useRef();

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Need to initialize a blank item
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		itemloaded: false, //POSSIBLY MOST IMPORTANT! Using defaultValue on an input causes issues if you try to render before item is loaded.
		pendingupdate: false,
		itemdata: {
			ProductID: id
		},
		SomeBooleanDefault: true,
		RunDailyStatsDefault:false
	});
	
	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	const itemRefs = useRef({
		CostEl: "",
		ShippingCostEl: ""
	})

	//Load Item
	function LoadItem() {
		const postdata = {
			ProductID: localstate.itemdata.ProductID
		};
		axios.post(dbendpoint + "/products/getproduct", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					localstate.itemdata = res.data.item;

					//RECORD HISTORY DROP IN
					localstate.itemdata.RecordHistory = JSON.parse(localstate.itemdata.RecordHistory);

					//PieChat Helper
					//Create data for piechart!
					localstate.itemdata.piechartdata = [];
					if (localstate.itemdata.GradeACount>0){
						localstate.itemdata.piechartdata.push({
							id:"A",
							label: "Grade A",
							value:localstate.itemdata.GradeACount
						})
					}

					if (localstate.itemdata.GradeBCount>0){
						localstate.itemdata.piechartdata.push({
							id:"B",
							label: "Grade B",
							value:localstate.itemdata.GradeBCount
						})
					}

					if (localstate.itemdata.GradeCCount>0){
						localstate.itemdata.piechartdata.push({
							id:"C",
							label: "Grade C",
							value:localstate.itemdata.GradeCCount
						})
					}

					if (localstate.itemdata.GradeRepairCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Repair",
							label: "Grade Repair",
							value:localstate.itemdata.GradeRepairCount
						})
					}

					if (localstate.itemdata.GradeScrapCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Scrap",
							label: "Grade Scrap",
							value:localstate.itemdata.GradeScrapCount
						})
					}

					if (localstate.itemdata.GradeUnknownCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Unknown",
							label: "Grade Unknown",
							value:localstate.itemdata.GradeUnknownCount
						})
					}

					//Cost is always nice to have pre-formatted
					localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);
					localstate.dbreload = false;
					//Set Defaults on first load:
					if (!localstate.itemloaded){
						localstate.itemdatadefaults = localstate.itemdata;
						localstate.itemloaded = true; //Now allow render
					}
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}



	useEffect(() => {
		//To Do: On load of item, set title to the serial number.
		document.title = "Product";
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			//Diagnostic:
			console.log("Localstate ID: " + localstate.itemdata.ID);
			LoadItem();
		}
	}, [state]);


	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue, event) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (event.key === "." || event.key === "Period" || event.key === "NumpadDecimal") {
			console.log("Decimal key detected in Restrict Number");
			return false;
		}
		if (event.key === "Backspace") {
			console.log("Backspace key detected!");
			//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value
			if ((oldvalue.indexOf(".") > -1) && (index === -1)) {
				return false;
			}
		}
		if (index > -1) {
			if ((len - 1) > (index + 2)) {
				//We are out past 2 decimals!
				return oldvalue;
			}
		}
		return newvalue;
	}


	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		let postdata = {
			item: localstate.itemdata
		}
		axios.post(dbendpoint + "/products/update", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					btnSave.current.style.display="";
					btnPendingSave.current.style.display="none";
					//Attempt to replace: btnSave.current.disabled = true;
					localstate.pendingsaves = false;
					localstate.dbreload = true;
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" })
			}
		});
	}



	//Changes to item
	const onChangeValue = (event, itemkey) => {
		var oldvalue = localstate.itemdata[itemkey];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {

			if (itemkey === "Cost" || itemkey === "ShippingCost") {
				if (!oldvalue){
					oldvalue="0";
				}
				//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
				if (newvalue) {
					itemRefs.current[itemkey+"El"].value = newvalue;
					localstate.itemdata[itemkey] = newvalue;
					//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
					const inputElement = itemRefs.current[itemkey+"El"];
					inputElement.type = 'text';
					inputElement.focus();
					inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
					inputElement.type = 'number';
				} else {
					console.log("No newvalue returned, set value in localstate, but leave input untouched.");
					console.log(parseFloat(event.target.value).toFixed(2));
					localstate.itemdata[itemkey] = parseFloat(event.target.value).toFixed(2);
				}
			}

			if (itemkey === "RunDailyStats") {
				if (event.target.checked) {
					localstate.itemdata.RunDailyStats = 1;
				} else {
					localstate.itemdata.RunDailyStats = 0;
				}
			}

			if (itemkey === "SomeBoolean") {
				if (event.target.checked) {
					localstate.itemdata.SomeBoolean = 1;
				} else {
					localstate.itemdata.SomeBoolean = 0;
				}
			}


		
			//All others
			if (itemkey === "Name" || itemkey === "LastName" || itemkey==="Date" || itemkey==="Mfg" || itemkey==="PartsLocation") {
				localstate.itemdata[itemkey] = newvalue;
			}
			localstate.pendingsaves = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
		}

	}



	//Try new method to target what is sent from a datetime picker:
	const onChangeDatetimeValue = (newvalue, itemkey) => {
		localstate.itemdata[itemkey]=newvalue;
		localstate.pendingsaves = true;
		btnSave.current.style.color = "white";
		btnSave.current.style.backgroundColor = "#01579B";
		UpdateState(localstate);

	}

	//Product types Autocomplete
	//Autocomplete Simple
	//Example: Products
	const [openproducttypes, openProductTypes] = React.useState(false);
	const [producttypes, setProductTypes] = React.useState([]);
	const [loadingproducttypes, setLoadingProductTypes] = useState(false);
	const [producttypesearchterm, setProductTypeSearchTerm] = useState("Default Value");
	//For loading single items that do have a value, use LoadItem function in conjunction with: setProductSearchTerm(res.data.item.product.Name);
	const InitProductTypes = () => {
		if (producttypes.length===0){
			ProductTypeSearch("");
		}
		openProductTypes(true);
	}

	const onChangeProductType = (event, newvalue) =>{
		localstate.itemdata.ProductType = newvalue.Name;
		localstate.pendingsaves = true;
		btnSave.current.style.display="none";
		btnPendingSave.current.style.display="";
		// btnSave.current.style.color = "white";
		// btnSave.current.style.backgroundColor = "#01579B";
		UpdateState(localstate);
	}

	const ProductTypeSearch = debounce(function(searchvalue){
		setProductTypeSearchTerm(searchvalue);
		setLoadingProductTypes(true);
		const postdata = {					
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getproducttypes", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
					setProductTypes(res.data);
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingProductTypes(false);
		});
	},600);

	const GoToProductsTable = () => {
		history.push('/products');
	}



	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
				{/* Standard Page Header with right floated error message space */}
				<div style={{ position: "relative", height: "55px", paddingTop: "5px" }}>
					<div style={{ textAlign: "center" }}>
						<h2>Product: {localstate.itemdata.Name}</h2>
					</div>

				<Box sx={{ '& button': { m: 1 }, displayPrint: "none" }}>
					<Button
						className={classes.bluebtn}
						color="primary" variant="contained"
						onClick={() => GoToProductsTable()}>
						<KeyboardBackspaceIcon sx={{ color: "lightgray" }}></KeyboardBackspaceIcon>&nbsp;Back to Product Table
					</Button>


					{(userPerms.updateProduct === 1) &&
						<>
							<Button
								className={(userPerms.updateProduct === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								ref={el => btnSave.current = el}>
								<SaveIcon sx={{ color: "lightgray" }}></SaveIcon>&nbsp;Save Changes
							</Button>
							<Button
								className={(userPerms.updateProduct === 1 || userPerms.createProduct === 1) ? classes.bluebtn : classes.hidden}
								color="primary" variant="contained"
								style={{ display: "none" }}
								onClick={() => SaveChanges()}
								ref={el => btnPendingSave.current = el}>
								<PendingIcon sx={{ color: "orange" }}></PendingIcon>&nbsp;Save Changes
							</Button>
						</>
					}

					<Button
						className={classes.bluebtn}
						color="primary" variant="contained"
						onClick={() => props.handlePrint()}>
						Print
					</Button>

					{(userPerms.readItem === 1) &&
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => {
								//history.push("/inventorymanager?Model=" + encodeURIComponent(localstate.itemdata.Name));
								window.open("/inventorymanager?Model=" + encodeURIComponent(localstate.itemdata.Name), '_blank').focus();
							}
							}>
							<ViewListIcon className={classes.transparenticon}>
							</ViewListIcon>
							&nbsp;
							View Inventory
						</Button>
					}

					{(userPerms.readSku === 1) &&
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => {

								//history.push("/skus?ProductID=" + localstate.itemdata.ProductID + "&ProductName=" + encodeURIComponent(localstate.itemdata.Name))
								window.open("/skus?ProductID=" + localstate.itemdata.ProductID + "&ProductName=" + encodeURIComponent(localstate.itemdata.Name), '_blank').focus();

							}
							}>
							<ViewListIcon className={classes.transparenticon}>
							</ViewListIcon>
							&nbsp;
							View Skus
						</Button>
					}
					{(userPerms.readOrder === 1) &&
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => {

								//history.push("/skus?ProductID=" + localstate.itemdata.ProductID + "&ProductName=" + encodeURIComponent(localstate.itemdata.Name))
								window.open("/orderstable?ProductID=" + localstate.itemdata.ProductID + "&ProductName=" + encodeURIComponent(localstate.itemdata.Name), '_blank').focus();

							}
							}>
							<ViewListIcon className={classes.transparenticon}>
							</ViewListIcon>
							&nbsp;
							View Orders
						</Button>

					}
				</Box>

					<Box sx={{ height: "26px", displayPrint:"none" }}>
						{(errors.currenterror.errshow) &&
							<React.Fragment>
								<ErrorMessage />
							</React.Fragment>
						}
					</Box>


				</div>

				{(localstate.itemloaded) &&
				<>
				<div className={classes.itemtableheader}>Details</div>
					<Grid container spacing={0} alignItems="center" justifyContent="center">
						<Grid item sm={12} md={6}>
							<div className={classes.flextabledebug}>
								<table className={classes.itemtable}>
									<tbody>
										<tr>
											<td style={{ width: "160px" }}>
												ID
											</td>
											<td>
												<div className={classes.flexitemstaticinput}>
													{localstate.itemdata.ProductID}
												</div>

											</td>
										</tr>
										<tr>
											<td>
												Name:
											</td>
											<td>
												{(userPerms.updateProduct===1) &&
													<input className={classes.flexiteminput}
														type="text"
														value={localstate.itemdata.Name}
														onChange={(event) => onChangeValue(event, "Name")}
													/>
												}
												{(userPerms.updateProduct===0) &&
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.Name}
													</div>
												}												
											</td>
										</tr>
										<tr>
											<td>
												Mfg:
											</td>
											<td>
												{(userPerms.updateProduct===1) &&
													<input className={classes.flexiteminput}
														type="text"
														value={localstate.itemdata.Mfg}
														onChange={(event) => onChangeValue(event, "Mfg")}
													/>
												}
												{(userPerms.updateProduct===0) &&
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.Mfg}
													</div>
												}												
											</td>
										</tr>



										{/* Product Types with default as current product type */}

										<tr>
											<td>
												Type:
											</td>
											<td>
												{(userPerms.updateProduct===1) &&
													<Autocomplete
													value={localstate.itemdata.AssetType}
													freeSolo forcePopupIcon={false} disableClearable style={{ width: "100%" }}
													className={classes.flexitemautocompleteinput}
													open={openproducttypes} onOpen={() => { InitProductTypes(); }} onClose={() => { openProductTypes(false); }}
													//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
													onChange={(event, newValue) => onChangeProductType(event, newValue)}
													onInputChange={(event) => ProductTypeSearch(event.target.value)}
													isOptionEqualToValue={(option, value) => option['Name'] === value}
													getOptionLabel={(option) => option['Name']}
													options={producttypes}
													loading={loadingproducttypes}
													//Setting value or defaultValue kinda breaks this. Use placeholder instead based on a useState variable
													renderInput={(params) => (
														<div ref={params.InputProps.ref} style={{height:"30px"}}>
															<input placeholder={localstate.itemdata.ProductType} {...params.inputProps} />
														</div>
													)}
												/>
												}
												{(userPerms.updateProduct===0) &&
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.Mfg}
													</div>
												}												
											</td>
										</tr>
										
										<tr>
											<td>
												Cost:
											</td>
											<td>
												{(userPerms.updateProduct===1) &&
													<input className={classes.flexiteminput}
													ref={el => itemRefs.current.CostEl = el}
													type="number"
													step="0.01"
													defaultValue={localstate.itemdata.Cost}
													onKeyUp={(event) => onChangeValue(event, "Cost")}
												/>
												}
												{(userPerms.updateProduct===0) &&
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.Cost}
													</div>
												}													
											</td>
										</tr>

										<tr>
											<td>
												Ship Cost:
											</td>
											<td>
												{(userPerms.updateProduct===1) &&
													<input className={classes.flexiteminput}
													ref={el => itemRefs.current.ShippingCostEl = el}
													type="number"
													step="0.01"
													defaultValue={localstate.itemdata.ShippingCost}
													onKeyUp={(event) => onChangeValue(event, "ShippingCost")}
												/>
												}
												{(userPerms.updateProduct===0) &&
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.ShippingCost}
													</div>
												}													
											</td>
										</tr>
										<tr>
											<td>
												Parts Location:
											</td>
											<td>
												{(userPerms.updateProduct===1) &&
													<input className={classes.flexiteminput}
														type="text"
														value={localstate.itemdata.PartsLocation}
														onChange={(event) => onChangeValue(event, "PartsLocation")}
													/>
												}
												{(userPerms.updateProduct===0) &&
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.PartsLocation}
													</div>
												}												
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</Grid>
						<Grid item sm={12} md={6}>
							<div className={classes.flextabledebug}>
								<table className={classes.itemtable}>
									<tbody>
										<tr>
											<td style={{ width: "160px", paddingTop: "8px" }}>
												Run Daily Stats:
											</td>
											<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
												{(userPerms.updateProduct===1) &&
													<Checkbox
													checked={(localstate.itemdata.RunDailyStats === 1 ? true : false)}
													className={classes.gridcheckbox}
													onClick={(event) => onChangeValue(event, "RunDailyStats")} />
												}
												{(userPerms.updateProduct===0) &&
													<Checkbox
													disabled
													checked={(localstate.itemdata.RunDailyStats === 1 ? true : false)}
													onClick={(event) => onChangeValue(event, "RunDailyStats")} />
												}												
											</td>
										</tr>

										<tr>
											<td>
												Creation:
											</td>
											<td>
												<div className={classes.flexitemstaticinput}>
													<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.created_at}</Moment>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												Created By:
											</td>
											<td>
												<div className={classes.flexitemstaticinput}>
													{localstate.itemdata.CreatedBy}
												</div>
											</td>
										</tr>
										<tr>
											<td>
												Updated:
											</td>
											<td>
												<div className={classes.flexitemstaticinput}>
													<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.updated_at}</Moment>
												</div>											
											</td>
										</tr>
										<tr>
											<td>
												Updated By:
											</td>
											<td>
												<div className={classes.flexitemstaticinput}>
													{localstate.itemdata.updated_by}
												</div>
											</td>
										</tr>
										<tr>
											<td>
											&nbsp;
											</td>
											<td>
												
											</td>
										</tr>
										
								
									</tbody>
								</table>
							</div>
						</Grid>
					</Grid>
				</>

					
				}
		</LocalizationProvider>
	)
}

export default ProductDetails;
