//DocumentView

//New Concept:
//When React goes to rerender an input, it must have a key. Keys need to all be the same as the previous render, container and child container!
//Otherwise inputs WILL lose focus!

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";
import cloneDeep from 'lodash/cloneDeep';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';


import { useSelector, useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//FlexDocument
import { FlexDocument } from '../Documents/FlexDocument';

//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout
} from '../../features/error/errormessageSlice';

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext";
//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//Datetime formatting
import Moment from 'react-moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime
import dayjs from 'dayjs'; //Used with new datetimepickers
//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";




import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

//Buttons
import Button from '@mui/material/Button';

//Datetime Pickers
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//DBAutoComplete
import DBAutoComplete from '../common/DBAutoComplete';

//Printing
import { useReactToPrint } from 'react-to-print';

//Record History
import RecordHistory from '../common/RecordHistory';


/* ##########################  Configuration  ########################## */

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Axios Long Post
const longpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 20000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1, 1, 2, 3, 3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function (acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	}, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};



const DocumentView = (props) => {
	document.title="User Document";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Documents"));
	dispatch(setCurrentMenuItem("/documents"));
	//URL Param for ID
	let { id } = useParams();
	console.log(id);

	let history = useHistory();

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();
	const btnPendingSave = useRef();

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Need to initialize a blank item
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		itemloaded: false, //POSSIBLY MOST IMPORTANT! Using defaultValue on an input causes issues if you try to render before item is loaded.
		pendingupdate: false,
		auditview:false,
		historyavailable:false,
		recordhistory:[],
		historyindex:0,
		itemdiffs:[],
		itemdata: {
			ID: id,
			Name: "default",
			Cost: 0,
			Price: 0,
			Margin: 0,
			SomeBoolean: true,
			PositiveInteger:3
		},
		SomeBooleanDefault: true
	});

	//Some inputs will need to work via refs because we may be restricting some inputs and updating both the input itself and the JS variable
	const itemRefs = useRef({
		CostEl: "",
		PriceEl: "",
		MarginEl: "",
		PositiveIntegerEl:""
	})

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	//Ref used for printing
	const printRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	//Load Item
	function LoadItem() {
		const postdata = {
			ID: localstate.itemdata.ID
		};
		axios.post(dbendpoint + "/document", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					localstate.itemdata = res.data.item;

					//Datetime Formatting
					// localstate.itemdata.Date = dayjs(localstate.itemdata.Date);
					// localstate.itemdata.updated_at = dayjs(localstate.itemdata.updated_at);
					// localstate.itemdata.created_at = dayjs(localstate.itemdata.created_at);

					//RECORD HISTORY DROP IN
					if (localstate.itemdata.hasOwnProperty('RecordHistory')) {
						if (localstate.itemdata.RecordHistory){
							localstate.recordhistory = localstate.itemdata.RecordHistory;
							//Sort by updated_at asc (Change a/b for desc)
							localstate.recordhistory = localstate.recordhistory.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
							if (localstate.recordhistory.length > 0) {
								localstate.historyavailable = true;
							}
						}
					}		
					
					//TO DO - USE TERNARY IN JSX ON localstate.editable instead of all those tedious, individually evalutated ones.

					//Evaluate Editable:
					localstate.editable = false;
					//If user can create and the item is PendingItem, allow a single edit.
					if (localstate.itemdata.PendingItem === 1 && userPerms.createDemoData === 1){
						localstate.editable = true;
					} else if (userPerms.updateDemoData === 1){
						localstate.editable = true;
					}

					//Cost is always nice to have pre-formatted
					localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);
					localstate.dbreload = false;
					//Set Defaults on first load:
					if (!localstate.itemloaded){
						localstate.itemdatadefaults = localstate.itemdata;
						localstate.itemloaded = true; //Now allow render
					}
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 10, errtype: "neutral" }));
					dispatch(setErrorTimeout(10));
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" }));
				dispatch(setErrorTimeout(15));
			}
		});
	}



	useEffect(() => {
		//To Do: On load of item, set title to the serial number.
		document.title = "Boilerplate Key Values";
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			//Diagnostic:
			console.log("Localstate ID: " + localstate.itemdata.ID);
			LoadItem();
		}
	}, [state]);


	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	const SaveChanges = () => {
		//Hide current errors:
		dispatch(setErrorTimeout(0));

		if (localstate.itemdata.Name.length === 0){
			dispatch(newErrorMessage({ errmsg: "warning", errshow: true, errtimeout: 15, errtype: "warning" }));
			dispatch(setErrorTimeout(15));
			return;
		}

		let postdata = {
			item: localstate.itemdata
		}
		axios.post(dbendpoint + "/basictable/update", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					btnSave.current.style.display="";
					btnPendingSave.current.style.display="none";
					//Attempt to replace: btnSave.current.disabled = true;
					localstate.pendingsaves = false;
					localstate.dbreload = true;
					UpdateState(localstate);
					dispatch(newErrorMessage({ errmsg: "Saved successfully.", errshow: true, errtimeout: 15, errtype: "ok" }));
					dispatch(setErrorTimeout(10));
				}
				if (res.data.Status === "Failure") {
					//Failure error
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" }));
					dispatch(setErrorTimeout(15));
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" }));
				dispatch(setErrorTimeout(15));
			}
		});
	}



	//Changes to item
	const onChangeValue = (event, itemkey) => {
		var oldvalue = localstate.itemdata[itemkey];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {

			if (itemkey === "SomeBoolean") {
				if (event.target.checked) {
					localstate.itemdata.SomeBoolean = 1;
				} else {
					localstate.itemdata.SomeBoolean = 0;
				}
			}

			//Float
			if (itemkey === "Cost" || itemkey === "Price" || itemkey === "Margin") {
				if (!oldvalue){
					oldvalue="0";
				}
				//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
				if (newvalue) {
					itemRefs.current[itemkey+"El"].value = newvalue;
					localstate.itemdata[itemkey] = newvalue;
					//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
					const inputElement = itemRefs.current[itemkey+"El"];
					inputElement.type = 'text';
					inputElement.focus();
					inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
					inputElement.type = 'number';
				} else {
					console.log("No newvalue returned, set value in localstate, but leave input untouched.");
					console.log(parseFloat(event.target.value).toFixed(2));
					localstate.itemdata[itemkey] = parseFloat(event.target.value).toFixed(2);
				}
			}

			//Positive Integer:
			if (itemkey === "PositiveInteger") {
				if (!oldvalue){
					oldvalue="0";
				}
				//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "positiveinteger"); //All arguments required. New universal function for all pages. However handle result below.
				if (newvalue) {
					itemRefs.current[itemkey+"El"].value = newvalue;
					localstate.itemdata[itemkey] = newvalue;
					//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
					const inputElement = itemRefs.current[itemkey+"El"];
					inputElement.type = 'text';
					inputElement.focus();
					inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
					inputElement.type = 'number';
				} else {
					console.log("No newvalue returned, set value in localstate, but leave input untouched.");
					console.log(parseInt(event.target.value));
					localstate.itemdata[itemkey] = parseInt(event.target.value);
				}
			}

			//All others
			if (itemkey === "Name" || itemkey === "LastName" || itemkey==="Date") {
				localstate.itemdata[itemkey] = newvalue;
			}
			localstate.pendingsaves = true;
			//Old
			// btnSave.current.style.color = "white";
			// btnSave.current.style.backgroundColor = "#01579B";
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			UpdateState(localstate);
		}

	}



	//Try new method to target what is sent from a datetime picker:
	const onChangeDatetimeValue = (newvalue, itemkey) => {
		localstate.itemdata[itemkey] = newvalue;
		localstate.pendingsaves = true;
		//Old
		// btnSave.current.style.color = "white";
		// btnSave.current.style.backgroundColor = "#01579B";
		btnSave.current.style.display = "none";
		btnPendingSave.current.style.display = "";
		UpdateState(localstate);
	}


	//Record History
	const ViewRecordHistory = () => {
		//Initiate record history and load last history item for immediate viewing!
		var historylength = localstate.recordhistory.length;
		//Use length-1 for last index
		localstate.historyindex = historylength - 1;
		//localstate.itemdata = Object.assign({}, localstate.recordhistory[historylength-1]);
		localstate.itemdata = cloneDeep(localstate.recordhistory[historylength - 1]);
		//Diffs
		if ((historylength - 1) !== 0) {
			GetDiffs(localstate.itemdata, localstate.recordhistory[localstate.recordhistory.length - 2]);
		} else {
			//Reset diffs:
			for (let key in localstate.itemdata) {
				localstate.itemdiffs[key] = {};
				localstate.itemdiffs[key].dirty = false;
			}
		}
		//Pre-Formatted currency
		localstate.itemdata.Cost = parseFloat(localstate.recordhistory[localstate.recordhistory.length - 1].Cost).toFixed(2);
		localstate.auditview = true;
		UpdateState(localstate);
	}

	const ViewRecord = (newindex) => {
		//Provision to go back to live view:
		if (newindex === localstate.recordhistory.length) {
			//All out of history records, go back to live
			localstate.dbreload = true;
			localstate.auditview = false;
			localstate.itemloaded = false; //Helps rerender! No kidding. After reload, it'll force rerender contents.
			UpdateState(localstate);
		} else {
			localstate.historyindex = newindex;
			localstate.itemdata = localstate.recordhistory[newindex];
			//Diffs
			localstate.itemdiffs = {};
			if (newindex !== 0) {
				GetDiffs(localstate.itemdata, localstate.recordhistory[newindex - 1]);
			} else {
				//Reset diffs:
				for (let key in localstate.itemdata) {
					localstate.itemdiffs[key] = {};
					localstate.itemdiffs[key].dirty = false;
				}
			}
			//Pre-Formatted currency
			localstate.itemdata.Cost = parseFloat(localstate.itemdata.Cost).toFixed(2);
			UpdateState(localstate);
		}
	}

	const GetDiffs = (currentrecord, previousrecord) => {
		for (let key in currentrecord) {
			localstate.itemdiffs[key] = {};
			if (currentrecord[key] !== previousrecord[key]) {
				localstate.itemdiffs[key].dirty = true;
				//We can also setup some kind of greaterthan / lessthan setup one day. 
				//localstate.itemdiffs[key].greaterthanvalue = something;
			} else {
				localstate.itemdiffs[key].dirty = false;
			}
		}
	}

	const RestoreRecord = () => {
		//Notice: YOU WILL NEED TO MAKE ADJUSTMENTS TO YOUR API TO INCLUDE NESTED RELATIONSHIPS
		//Assuming everything is stored in localstate.itemdata (our traversal of RecordHistory helps this)
		//Let's try to send it right back to our update:
		//Send a flag with our item so our update() api endpoint can make specific adjustments.
		localstate.itemloaded = false; //Forces rerender.
		localstate.itemdata["RestoreRecord"] = 1;
		localstate.auditview = false;

		SaveChanges();
	}

	const GoToBoilerplateTable = () => {
		history.push('/boilerplatetable');
	}


	//Let's try to set a default value for our AutoComplete
	const [testDBAC, setDBAC] = useState('Some UseState Default');

	const onDBAutoCompleteChange = (newvalue, searchkey) =>{
		console.log(newvalue);
		//We can parse results by using keys - this makes the function reusable so we can insert many DBAutoComplete Components
		if (searchkey==="products"){
			//console.log("Set new value: "+newvalue.Vendor);
			//setDBAC(newvalue.Vendor);


			localstate.itemdata.ProductID = newvalue.ProductID;
			btnSave.current.style.display = "none";
			btnPendingSave.current.style.display = "";
			UpdateState(localstate);
			
		} 
	}





	
	/* ##########################  Post Test  ########################## */
	//Useful for making test API calls.
	// Simply remove customendpoint if you want to call the basic testpost method at the top of BasicTableController.
	const TestPost = (customendpoint) =>{
		if(!customendpoint){customendpoint="/testpost"}
		//Sample postdata.
		const postdata = {
			item:localstate.itemdata
		};
		axios.post(dbendpoint+customendpoint, postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					console.log(res);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					dispatch(newErrorMessage({ errmsg: res.data.message, errshow: true, errtimeout: 10, errtype: "neutral" }));
					dispatch(setErrorTimeout(10));
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" }));
				dispatch(setErrorTimeout(15));
			}
		});
	}


	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/
	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			{(localstate.itemloaded) &&
			<div style={{ padding: "8px", textAlign: "center", margin: "auto", marginBottom: "100px", overflow:"auto", maxWidth:"1200px" }} ref={printRef}>
				<div style={{marginLeft:"30px"}}> {/* This is how we allow the Plus icon to show */}
					<FlexDocument
					itemid={localstate.itemdata.ID} //May be ProductID, ItemID, InternalOrderID, etc. Also adjust your API endpoint.
					dbkey={"ID"} //This lets our controller know what Document key we should update. Items may have more than one type of Document (TechDocID, MarketingDocID)
					documentid={localstate.itemdata.ID} //If any document at this ID is currently linked, FlexDocument will load it.
					title={localstate.itemdata.Title} //This sends a usable title to be saved in the document system 
					allownew={false} //Should already be a document on this page.
					documentendpoint={'/document'} //This is to give FlexDocument the adddocument endpoint, eg: /basictable/adddocument
					//These values are applied to the flexdocument database item and allow us to reverse-navigate to a document's parent:
					documenttype={"user"} //Allows traversal to parent /basictable/{itemid}
					//Default right now everyone can make a userDoc
					userPerms={{
						create: 1, //Anyone can create a new user doc
						read: 1, //Anyone can read any document
						update: (appContext.userId === localstate.itemdata.CreatedByUserID || appContext.userRole==='admin' ? 1 : 0),
						delete: (appContext.userId === localstate.itemdata.CreatedByUserID || appContext.userRole==='admin' ? 1 : 0),
					}}
					readOnly={((localstate.itemdata.PendingItem === 1 && appContext.userId === localstate.itemdata.CreatedByUserID) ? false:true)} 
					tools={{
						header: true,
						list: true,
						image: true,
						table: true,
						checklist: true,
						dragdrop: true, //This breaks if you start off with readOnly=true from props
						alert: true,
						toggle: true,
						code: true,
						color: false, //Disabled until plugin 2.0.4 is updated to something working.
						marker: false,//Disabled until plugin 2.0.4 is updated to something working.
						raw: true,
					}}
					addbtntext={"Add Document"}	//This is not used in DocumentView
					editbtntext={"Edit Document"} //Document, Notes, etc
					savebtntext={"Save Document"}
					//header={"Optional static header for document section."} //This is not used in DocumentView
					//introduction={"Optional static introduction for document section."} //This is not used in DocumentView
					showupdateinfo={true}
				/>
				</div>
				
			</div>
		}
		</LocalizationProvider>
	)
}

export default DocumentView;
